<template>
  <div class="syssetting">
    <Table size="small" :columns="columns" :data="data" :loading="table_loading">
      <template slot-scope="row" slot="value">
          <input v-if="row.row.filltype=='input'" autocomplete="off" type="text" class="ivu-input fillinp" maxlength="50" v-model="data[row.index].value" :disabled="row.row.system==1?true:false" @blur="fill(row.index)" />
        <RadioGroup v-else-if="row.row.filltype=='radio'" v-model="data[row.index].value" @on-change="fill(row.index)">
          <Radio :label="k" v-for="(p,k) in row.row.avalue" :key="k">{{p}}</Radio>
        </RadioGroup>
        <CheckboxGroup v-else-if="row.row.filltype=='checkbox'" v-model="data[row.index].value" @on-change="fill(row.index)">
          <Checkbox :label="k" v-for="(p,k) in row.row.avalue" :key="k">{{p}}</Checkbox>
        </CheckboxGroup>
        <div class="tagsw" v-else-if="row.row.filltype=='tag'">
          <Tag closable color="default" v-for="(p,k) in data[row.index].value" :key="k" @on-close="del_tag(row.index,k)">{{p}}</Tag>
          <Button type="primary" size="small" @click="additem(row.index)">添加</Button>
        </div>
      </template>
    </Table>
    <Modal :value="editdata.status" title="添加类别" width="300" :closable="false" :mask-closable="false">
      <input autocomplete="off" type="text" class="ivu-input fillinp" maxlength="15" v-model="editdata.value" />
      <div class="moda-foot" slot="footer">
        <Button @click="close_modal" v-if="!editdata.loading">取消</Button>
        <Button type="primary" @click="save_edit" :loading="editdata.loading">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'Syssetting',
  data(){
    return {
      columns:[
        {title: '设置项',key: 'name',minWidth:100,maxWidth:300},
        {title: '设置值',slot: 'value',minWidth:200,maxWidth: 500},
        {title: '设置说明',key: 'body',minWidth:200}
      ],
      data:[],
      table_loading:false,
      editdata:{
        status:false,
        key:-1,
        value:'',
        loading:false
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (loading){
      var _this=this;
      this.table_loading=loading===false?false:true;
      this.requestApi('/adm/syssetting.html').then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 删除选项
     */
    del_tag:function (key,k){
      var _this=this;
      this.confirm('确定删除吗？',function (){
        _this.data[key].value.splice(k,1);
        _this.fill(key);
      })
    },
    /**
     * 添加选项
     */
    additem:function (key){
      this.editdata.key=key;
      this.editdata.status=true;
    },
    /**
     * 保存选项
     */
    save_edit:function (){
      this.editdata.value=this.editdata.value.trim();
      if(this.editdata.value=='') return this.alertError('请填写后再保存');
      this.data[this.editdata.key].value.push(this.editdata.value);
      this.fill(this.editdata.key);
      this.close_modal();
    },
    /**
     * 修改设置
     */
    fill:function (key){
      var va=this.data[key].value,reg=eval(this.data[key].pattern);
      if(reg && !reg.test(va)){
        this.data[key].value=this.data[key].yvalue;
        return this.alertError(this.data[key].name+' 不符合设置要求！');
      }
      var _this=this;
      this.requestApi('/adm/save_syssetting.html', {'id':this.data[key].id,'value':this.data[key].value}).then(function (res){
        if (res.status==1){
          _this.init(false);
        }else{
          _this.init(false);
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 关闭弹窗
     */
    close_modal:function (){
      this.editdata={
        status:false,
        key:-1,
        value:'',
        loading:false
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/syssetting";
</style>